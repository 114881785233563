import { useSearchParams } from 'react-router-dom'
import {
  SentimentDissatisfiedIcon,
  SentimentNeutralIcon,
  SentimentSatisfiedIcon,
  SentimentVeryDissatisfiedIcon,
  SentimentVerySatisfiedIcon
} from '@toasttab/buffet-pui-icons'
import { t } from '@local/translations'
import { FeedbackRating } from '../domain/guestFeedbackDetailsContext'

const URL_PARAM_RATING = 'rating'

interface RatingOption {
  type: FeedbackRating
  icon: React.ElementType
  index: number
}

export const ratingConfig: RatingOption[] = [
  { type: 'NEGATIVE', icon: SentimentVeryDissatisfiedIcon, index: 1 },
  { type: 'SOMEWHAT_NEGATIVE', icon: SentimentDissatisfiedIcon, index: 2 },
  { type: 'PASSIVE', icon: SentimentNeutralIcon, index: 3 },
  { type: 'SOMEWHAT_POSITIVE', icon: SentimentSatisfiedIcon, index: 4 },
  { type: 'POSITIVE', icon: SentimentVerySatisfiedIcon, index: 5 }
]

export const useGuestFeedbackRating = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setRating = (newRating: FeedbackRating) => () => {
    searchParams.delete(URL_PARAM_RATING)
    searchParams.set(URL_PARAM_RATING, newRating)
    setSearchParams(searchParams)
  }

  const clearRating = () => {
    searchParams.delete(URL_PARAM_RATING)
    setSearchParams(searchParams)
  }

  const RATINGS = ratingConfig.map(({ type, icon, index }) => ({
    type,
    action: setRating(type),
    label: t('rating', { context: type }),
    icon,
    index
  }))

  return {
    RATINGS,
    rating: getRating(searchParams),
    setRating,
    clearRating
  }
}

const validRatings: FeedbackRating[] = ratingConfig.map(({ type }) => type)

const getRating = (
  searchParams: URLSearchParams
): FeedbackRating | undefined => {
  const rating = searchParams.get(URL_PARAM_RATING) || ''
  const ratingUppercase = rating.toUpperCase()
  return validRatings.find((r) => r === ratingUppercase)
}
