import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { isIntl } from '../../domain/international'
import { t } from '@local/translations'

export const Disclaimers = () => {
  const { intlProps } = useGuestFeedbackContext()

  if (isIntl(intlProps)) {
    return (
      <p
        data-testid='intl-disclaimer'
        className='p-4 text-center type-subhead text-secondary'
      >
        {t('the-information-you-provide-will-be-processed-pursuant-to-toasts')}{' '}
        <a
          href='https://pos.toasttab.com/terms-of-service'
          target='_blank'
          className='text-link hover:text-link-hover hover:underline'
          rel='noreferrer'
        >
          {t('terms-of-service')}
        </a>{' '}
        {t('and')}{' '}
        <a
          href='https://pos.toasttab.com/privacy'
          target='_blank'
          className='text-link hover:text-link-hover hover:underline'
          rel='noreferrer'
        >
          {t('privacy-statement')}
        </a>
        .
      </p>
    )
  }

  return (
    <div className='space-y-4 p-4'>
      <p
        className='text-center type-subhead text-secondary'
        data-testid='contact-disclaimer'
      >
        {t(
          'the-restaurant-tracks-feedback-and-may-reach-out-using-the-contact-info-you-previously-provided'
        )}
      </p>
    </div>
  )
}
