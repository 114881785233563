import { useEffect } from 'react'

import { t } from '@local/translations'
import { HEADER_STYLES } from '../../domain/backgrounds'
import { Footer } from '../GuestFeedbackPage/Footer'
import { trackEvent } from '../../domain/tracking'
import { ThumbsOptions } from './ThumbsOptions'
import { EmojiOptions } from './EmojiOptions'
import { StarOptions } from './StarOptions'
import { FeatureFlags } from '../../configs/featureFlags'
import { useFeatureFlags } from '../../context/FeatureFlagsProvider'

export const GuestFeedbackRatingPage = () => {
  const featureflags = useFeatureFlags()

  const starsFF = featureflags[FeatureFlags.STARS_SURVEY]
  const emojisFF = featureflags[FeatureFlags.EMOJIS_SURVEY]

  useEffect(() => {
    trackEvent({ name: 'URL without rating' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options = () => {
    if (Object.keys(featureflags).length) {
      if (starsFF) return <StarOptions />
      if (emojisFF) return <EmojiOptions />
      else return <ThumbsOptions />
    }
    return null
  }

  return (
    <div className='flex flex-col h-screen'>
      <div className='flex-grow w-full' style={HEADER_STYLES}>
        <div className='px-4 pb-12 mx-auto' style={{ maxWidth: '20rem' }}>
          <h1 className='type-headline-4 text-default text-center pt-24'>
            {t('how-was-your-experience')}
          </h1>
          {options()}
          <p className='type-default text-default text-center pt-10'>
            {t('we-value-your-feedback-as-it-helps-us-continually-improve')}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
