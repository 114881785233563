import { useMutation } from 'react-query'

import { GuestFeedbackPayload } from '../domain/guestFeedbackDetailsContext'
import { useApi } from '../domain/api'

interface UseMutateGuestFeedbackProps {
  feedbackGuid: string
  onError?: () => void
  onSuccess?: () => void
}

export const useMutateGuestFeedback = ({
  feedbackGuid,
  onError,
  onSuccess
}: UseMutateGuestFeedbackProps) => {
  const { putGuestFeedback } = useApi()
  const { isLoading, mutate, isSuccess } = useMutation(
    (data: GuestFeedbackPayload) => {
      return putGuestFeedback(feedbackGuid, data)
    },
    {
      onSuccess,
      onError
    }
  )

  return {
    mutate,
    isLoading,
    isSuccess
  }
}
