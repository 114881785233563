import { IntlProps } from '../international'

export const FEEDBACK_REASONS = {
  Atmosphere: 'Atmosphere',
  Service: 'Service',
  'Food quality': 'Food quality',
  'Wait time': 'Wait time',
  Other: 'Other'
} as const

export type FeedbackReason = keyof typeof FEEDBACK_REASONS

export type FeedbackState = 'VALID' | 'ERROR' | 'EXPIRED'

export type FeedbackRating =
  | 'POSITIVE'
  | 'SOMEWHAT_POSITIVE'
  | 'PASSIVE'
  | 'SOMEWHAT_NEGATIVE'
  | 'NEGATIVE'

export type FeedbackRawRating = 'THUMBS' | 'FACES' | 'STARS'

export interface OrderItem {
  itemGuid: string
  displayName: string
  itemGroupGuid: string
  feedbackRating?: FeedbackRating
  feedbackRawRating?: FeedbackRawRating
}

export interface GuestFeedbackStateBase {
  restaurantGUID: string
  checkGUID: string
  orderGUID: string
  orderPaymentGUID: string
  feedbackRating: FeedbackRating
  feedbackRawRating: FeedbackRawRating
  feedbackSource: string
  feedbackReasons: string[]
  contactInformation: {
    optIn: boolean
    // Note: this object looks like it has other data that we need to pass to the submit (e.g. email)
  }
}

export interface GuestFeedbackResponse {
  guid: string
  restaurantName: string
  feedback: GuestFeedbackStateBase
  restaurantUrl: string
  feedbackState: FeedbackState
  reasons: string[]
  intlProps: IntlProps
}

export interface GuestFeedbackCombinedResponse extends GuestFeedbackResponse {
  orderItems: OrderItem[]
}

export interface GuestFeedbackQuery extends GuestFeedbackCombinedResponse {
  isLoading: boolean
  isError: boolean
  refetch: () => void
}

export interface GuestFeedbackState extends GuestFeedbackStateBase {
  orderItems: OrderItem[]
  feedbackItems: Record<string, FeedbackRating>
  feedbackFreeFormText: string
  complete: true // Note: this is hardcoded as the api needs this to be sent, so later it returns the EXPIRED state in future requests.
}

export type GuestFeedbackPayload = GuestFeedbackState

export interface GenericGuestFeedbackResponse {
  feedbackGUID: string
}

export interface GenericGuestFeedbackPayload {
  contactInformation: {
    email: string
    firstName: string
    lastName: string
    optIn: boolean
    phone?: string
  }
  feedbackFreeFormText: string
  feedbackRating: FeedbackRating
  feedbackRawRating: FeedbackRawRating
  feedbackReasons: FeedbackReason[]
  feedbackSource: string
  restaurantGUID: string
}

export interface GuestFeedbackMutation {
  saveFeedback: () => void
  saving: boolean
  isComplete: boolean
}

export interface GuestFeedbackContextType
  extends GuestFeedbackQuery,
    GuestFeedbackMutation {
  state: GuestFeedbackState
  updateState: (partialState: Partial<GuestFeedbackState>) => void
}
