import { useMemo, useState } from 'react'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { PhoneNumber } from '@toasttab/buffet-pui-phone-input'
import { isValidEmail } from '@toasttab/email-validation'
import { t } from '@local/translations'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import {
  FeedbackRating,
  FeedbackReason,
  GenericGuestFeedbackPayload
} from '../../domain/guestFeedbackDetailsContext'
import { trackEvent } from '../../domain/tracking'
import { useApi } from '../../domain/api'

export interface GenericGuestFeedbackFormValues {
  email: string
  feedbackReasons: FeedbackReason[]
  firstName: string
  lastName: string
  optIn: boolean
  phone?: PhoneNumber
  rating?: FeedbackRating
  textFeedback: string
}

export interface GenericGuestFeedbackForm {
  errors: Record<keyof GenericGuestFeedbackFormValues, boolean> | null
  submit: () => void
  submitting: boolean
  update: (newValues: Partial<GenericGuestFeedbackFormValues>) => void
  values: GenericGuestFeedbackFormValues
}

export const useGenericGuestFeedbackForm = () => {
  const { restaurantGuid = '' } = useParams()
  const { showErrorSnackBar } = useSnackBar()
  const [submitted, setSubmitted] = useState(false)
  const { postGuestFeedback } = useApi()

  const [values, setValues] = useState<GenericGuestFeedbackFormValues>({
    email: '',
    feedbackReasons: [],
    firstName: '',
    lastName: '',
    optIn: true,
    phone: undefined,
    rating: undefined,
    textFeedback: ''
  })

  const {
    mutate: submitGuestFeedback,
    data: submittedGuestFeedback,
    isLoading: submittingGuestFeedback
  } = useMutation(
    ['postGuestFeedback'],
    async (payload: GenericGuestFeedbackPayload) =>
      postGuestFeedback(restaurantGuid, payload),
    {
      onSuccess: () => {
        trackEvent({
          name: 'Generic feedback submitted',
          tags: {
            rating: values.rating || '',
            ...values.feedbackReasons.reduce(
              (acc, reason) => ({ ...acc, [reason]: 'true' }),
              {}
            )
          }
        })
      },
      onError: () => {
        showErrorSnackBar(
          t('we-cannot-accept-feedback-at-this-time-please-try-again-later')
        )
        trackEvent({ name: 'Generic feedback submission error' })
      }
    }
  )

  const errors: Record<keyof GenericGuestFeedbackFormValues, boolean> | null =
    useMemo(() => {
      const { email, firstName, phone, rating, textFeedback } = values
      return {
        email: email.length === 0 || !isValidEmail(email),
        feedbackReasons: false,
        firstName: firstName.length === 0,
        lastName: false,
        optIn: false,
        phone: phone?.nationalNumber
          ? !isValidPhoneNumber(phone.nationalNumber, {
              defaultCallingCode: phone.countryCode,
              defaultCountry: phone.countryIsoCode
            })
          : false,
        rating: rating === undefined,
        textFeedback: textFeedback.length === 0 || textFeedback.length > 250
      }
    }, [values])

  const updateValues = (newValues: Partial<GenericGuestFeedbackFormValues>) => {
    setValues((prevValues) => ({ ...prevValues, ...newValues }))
  }

  const submit = () => {
    setSubmitted(true)

    if (
      Object.values(errors).includes(true) ||
      !restaurantGuid ||
      values.rating === undefined
    ) {
      return
    }

    submitGuestFeedback({
      contactInformation: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        optIn: values.optIn,
        phone: values.phone
          ? `${values.phone.countryCode || ''}${
              values.phone.nationalNumber || ''
            }`
          : ''
      },
      feedbackFreeFormText: values.textFeedback,
      feedbackRating: values.rating,
      feedbackRawRating: 'THUMBS',
      feedbackReasons: values.feedbackReasons,
      feedbackSource: '',
      restaurantGUID: restaurantGuid
    })
  }

  return {
    errors: !submitted ? null : errors,
    submit,
    submitting: submittingGuestFeedback,
    submitted: Boolean(submittedGuestFeedback),
    update: updateValues,
    values
  }
}
