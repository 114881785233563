import React from 'react'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { validate } from 'uuid'

const clientSideIDs = {
  PRODUCTION: '59e4bfdd6292ac0ac9174386',
  PREPRODUCTION: '59f23d33a3862f0accd10d65'
}

export const useLdConfig = () => {
  const url = window.location.href
  const pathParts = url.split('/')
  const restaurantGuidFromURL = pathParts[5]
  const restaurantGuid = validate(restaurantGuidFromURL)
    ? restaurantGuidFromURL
    : ''

  return React.useMemo(() => {
    const env = getCurrentEnvironment()
    const isProd = env === 'prod'
    let clientSideID = process.env.LAUNCHDARKLY_CLIENT_SIDE_ID

    if (!clientSideID) {
      const { PRODUCTION, PREPRODUCTION } = clientSideIDs
      clientSideID = isProd ? PRODUCTION : PREPRODUCTION
    }
    return { clientSideID, key: restaurantGuid }
  }, [restaurantGuid])
}
