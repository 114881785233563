import { t } from '@local/translations'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'

export const Header = () => {
  const { restaurantName } = useGuestFeedbackContext()
  return (
    <p className='type-subhead text-default bg-primary-0 text-center py-2 px-4 font-semibold'>
      {restaurantName || t('restaurant-feedback')}
    </p>
  )
}
