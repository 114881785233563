import { t } from '@local/translations'

import { Disclaimers } from './Disclaimers'
import { FeatureFlags } from '../../configs/featureFlags'
import { LearnMore } from './LearnMore'
import { OrderItems } from './OrderItems'
import { Rating } from './Rating'
import { Reasons } from './Reasons'
import { SubmitButton } from './SubmitButton'
import { TextFeedback } from './TextFeedback'
import { isIntl } from '../../domain/international'
import { useFeatureFlags } from '../../context/FeatureFlagsProvider'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'

export const GuestFeedbackForm = () => {
  const { intlProps, state } = useGuestFeedbackContext()
  const featureFlags = useFeatureFlags()
  const showItemFeedback = featureFlags[FeatureFlags.MENU_ITEM]
  const textFeedbackPriority = featureFlags[FeatureFlags.COMMENT_BOX_PRIORITY]
  const isPositive =
    state.feedbackRating === 'POSITIVE' ||
    state.feedbackRating === 'SOMEWHAT_POSITIVE'

  return (
    <>
      <Rating />
      <p className='type-headline-4 text-default font-medium text-center pt-8'>
        {isPositive ? t('glad-to-hear') : t('sorry-to-hear-that')}
      </p>
      <p className='type-default text-default text-center'>
        {isPositive
          ? t('what-made-it-great')
          : t('what-could-have-been-better')}
      </p>
      {textFeedbackPriority && (
        <TextFeedback
          placeholder={t('tell-us-how-we-did-your-feedback-helps-us-improve')}
        />
      )}
      <Reasons />
      {showItemFeedback && <OrderItems />}
      {!textFeedbackPriority && (
        <TextFeedback
          placeholder={t(
            'tell-us-a-bit-more-your-insights-help-us-and-our-future-guests-max-250-characters'
          )}
        />
      )}
      {isIntl(intlProps) && <LearnMore />}
      <SubmitButton />
      <Disclaimers />
    </>
  )
}
