import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ThumbsDownIcon = React.forwardRef(function ThumbsDownIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M19.189 13.3V3.6m-3.3 9.7V4m0 9.3l-4.5 7c-1.5-.4-2.4-1.7-2-3l1.8-4h-5.5m10.2 0h5.1m-15.4 0c-1.7 0-2.9-1-2.5-2.6l1.5-5.8m16.4-1.3h-14s-2.1 0-2.4 1.4'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
