import { useState } from 'react'
import { StarIcon, StarFilledIcon } from '@toasttab/buffet-pui-icons'
import { useGuestFeedbackRating } from '../../hooks/useGuestFeedbackRating'
import { onEnter } from '../../domain/events'

export const StarOptions = () => {
  const { RATINGS } = useGuestFeedbackRating()
  const [hoveredIndex, setHoveredIndex] = useState(0)

  return (
    <div
      className='flex gap-2 justify-center mt-10'
      onMouseLeave={() => setHoveredIndex(0)}
    >
      {RATINGS.map(({ action, label, index, type }) => (
        <div
          key={type}
          role='button'
          tabIndex={0}
          onClick={action}
          onKeyDown={onEnter(action)}
          className='outline-none focus:shadow-focus rounded text-gray-50 hover:text-primary-75'
          onMouseEnter={() => setHoveredIndex(index)}
        >
          {hoveredIndex >= index ? (
            <StarFilledIcon aria-label={label} size='lg' />
          ) : (
            <StarIcon aria-label={label} size='lg' />
          )}
        </div>
      ))}
    </div>
  )
}
