import {
  GuestFeedbackCombinedResponse,
  GuestFeedbackPayload,
  GuestFeedbackResponse,
  OrderItem
} from '../domain/guestFeedbackDetailsContext'
import { GuestFeedbackUrlData } from '../domain/types'
import { intlPropsIELocale, intlPropsUSLocale } from './international'

export const mockShortUrl = 'my-happy-restaurant'
export const mockRestaurantGuid = 'guid-111-restaurant'
export const mockCheckGuid = 'guid-222-check'
export const mockOrderGuid = 'guid-333-order'
export const mockOrderPaymentGuid = 'guid-444-payment'
export const mockSearch = '?rating=POSITIVE&email=&phone='
export const mockFeedbackGuid = 'guid-555-feedback'
export const mockRestaurantName = 'My Happy Restaurant'

// URL data
export const MOCK_GUEST_FEEDBACK_URL = `/${mockShortUrl}/feedback/${mockRestaurantGuid}/${mockCheckGuid}/${mockOrderGuid}/${mockOrderPaymentGuid}/${mockSearch}`
export const MOCK_GUEST_FEEDBACK_URL_NO_SEARCH = `/${mockShortUrl}/feedback/${mockRestaurantGuid}/${mockCheckGuid}/${mockOrderGuid}/${mockOrderPaymentGuid}/`

export const mockUrlData: GuestFeedbackUrlData = {
  shortUrl: mockShortUrl,
  restaurantGuid: mockRestaurantGuid,
  checkGuid: mockCheckGuid,
  orderGuid: mockOrderGuid,
  orderPaymentGuid: mockOrderPaymentGuid,
  search: mockSearch,
  isBadUrlData: false
}

export const mockBadUrlData: GuestFeedbackUrlData = {
  shortUrl: '',
  restaurantGuid: '',
  checkGuid: '',
  orderGuid: '',
  orderPaymentGuid: '',
  search: '',
  isBadUrlData: true
}

// Get response with order item details
export const mockValidGuestFeedbackResponse: GuestFeedbackResponse = {
  guid: mockFeedbackGuid,
  restaurantName: mockRestaurantName,
  feedback: {
    restaurantGUID: mockRestaurantGuid,
    checkGUID: mockCheckGuid,
    orderGUID: mockOrderGuid,
    orderPaymentGUID: mockOrderPaymentGuid,
    feedbackRating: 'POSITIVE',
    feedbackRawRating: 'THUMBS',
    feedbackSource: 'My Source',
    feedbackReasons: [],
    contactInformation: {
      optIn: true
    }
  },
  restaurantUrl: mockShortUrl,
  feedbackState: 'VALID',
  reasons: ['Atmosphere', 'Service', 'Food quality', 'Wait time', 'Other'],
  intlProps: intlPropsUSLocale
}

export const mockOrderItemsResponse: OrderItem[] = [
  {
    itemGuid: 'guid-111-item',
    displayName: 'My Item',
    itemGroupGuid: 'guid-222-group'
  }
]

export const mockValidGuestFeedbackWithOrderItemsResponse: GuestFeedbackCombinedResponse =
  {
    ...mockValidGuestFeedbackResponse,
    orderItems: mockOrderItemsResponse
  }

// Get response without order item details
export const mockValidGuestFeedbackWithoutOrderItemsResponse: GuestFeedbackCombinedResponse =
  {
    guid: mockFeedbackGuid,
    restaurantName: mockRestaurantName,
    feedback: {
      restaurantGUID: mockRestaurantGuid,
      checkGUID: mockCheckGuid,
      orderGUID: mockOrderGuid,
      orderPaymentGUID: mockOrderPaymentGuid,
      feedbackRating: 'POSITIVE',
      feedbackRawRating: 'THUMBS',
      feedbackSource: 'My Source',
      feedbackReasons: [],
      contactInformation: {
        optIn: true
      }
    },
    restaurantUrl: mockShortUrl,
    feedbackState: 'VALID',
    reasons: ['Atmosphere', 'Service', 'Food quality', 'Wait time', 'Other'],
    intlProps: intlPropsUSLocale,
    orderItems: []
  }

export const mockPassiveGuestFeedbackResponse: GuestFeedbackResponse = {
  ...mockValidGuestFeedbackWithoutOrderItemsResponse,
  feedback: {
    ...mockValidGuestFeedbackWithoutOrderItemsResponse.feedback,
    feedbackRating: 'PASSIVE',
    feedbackRawRating: 'FACES'
  }
}

export const mockStarsGuestFeedbackResponse: GuestFeedbackResponse = {
  ...mockValidGuestFeedbackWithoutOrderItemsResponse,
  feedback: {
    ...mockValidGuestFeedbackWithoutOrderItemsResponse.feedback,
    feedbackRating: 'PASSIVE',
    feedbackRawRating: 'STARS'
  }
}

export const mockSomewhatPositiveGuestFeedbackResponse: GuestFeedbackResponse =
  {
    ...mockPassiveGuestFeedbackResponse,
    feedback: {
      ...mockPassiveGuestFeedbackResponse.feedback,
      feedbackRating: 'SOMEWHAT_POSITIVE'
    }
  }

export const mockErrorGuestFeedbackResponse: GuestFeedbackResponse = {
  ...mockValidGuestFeedbackWithoutOrderItemsResponse,
  feedbackState: 'ERROR'
}

export const mockExpiredGuestFeedbackResponse: GuestFeedbackResponse = {
  ...mockValidGuestFeedbackWithoutOrderItemsResponse,
  feedbackState: 'EXPIRED'
}

export const mockIntlGuestFeedbackResponse: GuestFeedbackResponse = {
  ...mockValidGuestFeedbackWithoutOrderItemsResponse,
  intlProps: intlPropsIELocale
}

// Put payload
export const mockGuestFeedbackPayload: GuestFeedbackPayload = {
  restaurantGUID: mockRestaurantGuid,
  checkGUID: mockCheckGuid,
  orderGUID: mockOrderGuid,
  orderPaymentGUID: mockOrderPaymentGuid,
  feedbackRating: 'POSITIVE',
  feedbackRawRating: 'THUMBS',
  feedbackSource: 'My Source',
  feedbackReasons: ['atmosphere'],
  contactInformation: {
    optIn: true
  },
  feedbackFreeFormText: 'Had a great meal.',
  complete: true,
  feedbackItems: {},
  orderItems: []
}
