import { ChangeEventHandler } from 'react'
import cx from 'classnames'

import { Textarea } from '@toasttab/buffet-pui-text-area'
import { t } from '@local/translations'

import { TextAreaProps } from '../Inputs/TextArea'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'

export const TextFeedback = ({
  className,
  testId = 'text-feedback',
  ...props
}: Omit<TextAreaProps, 'maxChars' | 'value' | 'onChange'>) => {
  const { state, updateState } = useGuestFeedbackContext()
  const { feedbackFreeFormText } = state

  const feedbackCharsLeft = (numChars: number, maxChars: number) => (
    <>{t('x-characters', { count: numChars, max: maxChars })}</>
  )

  const onChangeTextArea: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    updateState({ feedbackFreeFormText: event.target.value })
  }

  return (
    <div className='pt-6'>
      <Textarea
        {...props}
        className={cx('h-28', className)}
        testId={testId}
        value={feedbackFreeFormText}
        onChange={onChangeTextArea}
        maxChars={250}
        maxLength={250}
        maxCharsHelperTextFunc={feedbackCharsLeft}
      />
    </div>
  )
}
