import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GuestFeedbackProvider } from '../../context/GuestFeedbackContext'
import { trackEvent } from '../../domain/tracking'
import { useGuestFeedbackRating } from '../../hooks/useGuestFeedbackRating'
import { GuestFeedbackRatingPage } from '../GuestFeedbackRatingPage/GuestFeedbackRatingPage'
import { GuestFeedbackPage } from '../GuestFeedbackPage/GuestFeedbackPage'

export const GuestFeedback = () => {
  const { rating } = useGuestFeedbackRating()

  if (!rating) {
    return <GuestFeedbackRatingPage />
  }

  return (
    <GuestFeedbackProvider>
      <SearchParameterMetrics />
      <GuestFeedbackPage />
    </GuestFeedbackProvider>
  )
}

// Note: This is a temporary component so we can log some search parameters, and well be removed.
const SearchParameterMetrics = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    trackEvent({
      name: 'Search Parameter Metrics',
      tags: {
        hasEmail: !!searchParams.get('email'),
        hasPhone: !!searchParams.get('phone')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
