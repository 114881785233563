import { IntlProps } from '../domain/international'

export const intlPropsIELocale = {
  country: 'IE',
  language: 'en'
} as IntlProps

export const intlPropsUSLocale = {
  country: 'US',
  language: 'en'
} as IntlProps

export const intlPropsCALocale = {
  country: 'CA',
  language: 'en'
} as IntlProps

export const intlPropsGBLocale = {
  country: 'GB',
  language: 'en'
} as IntlProps

export const intlPropsEsUSLocale = {
  country: 'US',
  language: 'es'
} as IntlProps
