import { useLocation } from 'react-router-dom'
import { GuestFeedbackUrlData } from '../domain/types'

export const useGuestFeedbackUrlData = (): GuestFeedbackUrlData => {
  const { pathname, search } = useLocation()
  const pathParts = pathname.split('/')

  const shortUrl = pathParts[1]
  const feedbackText = pathParts[2]
  const restaurantGuid = pathParts[3]
  const checkGuid = pathParts[4]
  const orderGuid = pathParts[5]
  const orderPaymentGuid = pathParts[6]

  const guestFeedbackUrlData = {
    shortUrl,
    restaurantGuid,
    checkGuid,
    orderGuid,
    orderPaymentGuid,
    search
  }

  if (
    feedbackText !== 'feedback' ||
    !shortUrl ||
    !restaurantGuid ||
    !checkGuid ||
    !orderGuid ||
    !orderPaymentGuid ||
    !search
  ) {
    return {
      ...guestFeedbackUrlData,
      isBadUrlData: true
    }
  }

  return {
    ...guestFeedbackUrlData,
    isBadUrlData: false
  }
}
