export const GUEST_FEEDBACK_GET_URL =
  '/{SHORT_URL}/feedback-data/{RESTAURANT_GUID}/{CHECK_GUID}/{ORDER_GUID}/{ORDER_PAYMENT_GUID}/{SEARCH}'

export const G2_FRONTEND_URL_DEV = 'https://ws-dev.eng.toastteam.com'
export const G2_FRONTEND_URL_SANDBOX = 'https://ws-sandbox-api.eng.toasttab.com'
export const G2_FRONTEND_URL_PREPROD = 'https://ws-preprod-api.eng.toasttab.com'
export const G2_FRONTEND_URL_PROD = 'https://ws-api.toasttab.com'

export const GUEST_FEEDBACK_PUT_URL =
  '/guest-feedback/v1/guestfeedback/{FEEDBACK_GUID}/details'

export const GUEST_FEEDBACK_ORDER_ITEMS_LIST_URL =
  '/guest-feedback/v1/guestfeedback/orderItems/list?restaurantGuid={RESTAURANTGUID}&orderGuid={ORDERGUID}&checkGuid={CHECKGUID}&paymentGuid={PAYMENTGUID}&maxOrderItems=3'

export const GUEST_FEEDBACK_ORDER_ITEMS_PUT_URL = `/guest-feedback/v1/guestfeedback/bulk`

export const GUEST_FEEDBACK_POST_URL =
  '/guest-feedback/v1/guestfeedback/{RESTAURANT_GUID}/feedback'
