import { useGuestFeedbackRating } from '../../hooks/useGuestFeedbackRating'
import { onEnter } from '../../domain/events'

export const EmojiOptions = () => {
  const { RATINGS } = useGuestFeedbackRating()

  return (
    <div className='flex gap-2 justify-center mt-10'>
      {RATINGS.map(({ action, icon: Icon, label, type }) => (
        <div
          key={type}
          role='button'
          tabIndex={0}
          onClick={action}
          onKeyDown={onEnter(action)}
          className='outline-none focus:shadow-focus rounded text-gray-50 hover:text-primary-75'
        >
          <Icon aria-label={label} size='lg' />
        </div>
      ))}
    </div>
  )
}
