import { t } from '@local/translations'

import { Footer } from '../GuestFeedbackPage/Footer'
import { HEADER_STYLES } from '../../domain/backgrounds'
import { useGenericGuestFeedbackForm } from './useGenericGuestFeedbackForm'
import { GenericGuestFeedbackForm } from './GenericGuestFeedbackForm'
import {
  GuestFeedbackOutcomeNegative,
  GuestFeedbackOutcomePositive
} from '../GuestFeedbackOutcome/GuestFeedbackOutcome'

export const GenericGuestFeedback = () => {
  const form = useGenericGuestFeedbackForm()
  const submitted = form.submitted
  const positiveOutcome =
    form.values.rating === 'POSITIVE' ||
    form.values.rating === 'SOMEWHAT_POSITIVE'

  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex-grow w-full' style={HEADER_STYLES}>
        <h1 className='type-headline-4 text-default text-center pt-24'>
          {t('how-was-your-experience')}
        </h1>
        <div className='px-4 pb-12 mx-auto' style={{ maxWidth: '23rem' }}>
          {!submitted && <GenericGuestFeedbackForm {...form} />}
          {submitted && positiveOutcome && <GuestFeedbackOutcomePositive />}
          {submitted && !positiveOutcome && <GuestFeedbackOutcomeNegative />}
        </div>
      </div>
      <Footer />
    </div>
  )
}
