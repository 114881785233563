import React from 'react'

export const backspaceEvent = {
  key: 'Backspace'
} as React.KeyboardEvent<HTMLDivElement>

export const enterEvent = {
  key: 'Enter'
} as React.KeyboardEvent<HTMLDivElement>

export const spaceBarEvent = {
  key: ' '
} as React.KeyboardEvent<HTMLDivElement>
