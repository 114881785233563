import { noOpFn } from '../helpers'
import {
  GuestFeedbackQuery,
  GuestFeedbackContextType,
  GuestFeedbackMutation,
  GuestFeedbackStateBase,
  GuestFeedbackCombinedResponse,
  GuestFeedbackState
} from './types'

export const DEFAULT_GUEST_FEEDBACK_STATE_BASE: GuestFeedbackStateBase = {
  restaurantGUID: '',
  orderGUID: '',
  checkGUID: '',
  orderPaymentGUID: '',
  feedbackRating: 'POSITIVE',
  feedbackRawRating: 'THUMBS',
  feedbackSource: '',
  feedbackReasons: [],
  contactInformation: {
    optIn: true
  }
}

export const DEFAULT_GUEST_FEEDBACK_STATE: GuestFeedbackState = {
  ...DEFAULT_GUEST_FEEDBACK_STATE_BASE,
  orderItems: [],
  feedbackItems: {},
  feedbackFreeFormText: '',
  complete: true
}

export const DEFAULT_GUEST_FEEDBACK_RESPONSE: GuestFeedbackCombinedResponse = {
  guid: '',
  restaurantName: '',
  feedback: DEFAULT_GUEST_FEEDBACK_STATE_BASE,
  restaurantUrl: '',
  feedbackState: 'VALID',
  reasons: [],
  intlProps: {
    country: 'US',
    language: 'en'
  },
  orderItems: []
}

export const DEFAULT_GUEST_FEEDBACK_QUERY: GuestFeedbackQuery = {
  ...DEFAULT_GUEST_FEEDBACK_RESPONSE,
  isLoading: true,
  isError: false,
  refetch: noOpFn
}

export const DEFAULT_GUEST_FEEDBACK_MUTATION: GuestFeedbackMutation = {
  saveFeedback: noOpFn,
  saving: false,
  isComplete: false
}

export const DEFAULT_GUEST_FEEDBACK_CONTEXT: GuestFeedbackContextType = {
  ...DEFAULT_GUEST_FEEDBACK_QUERY,
  ...DEFAULT_GUEST_FEEDBACK_MUTATION,
  state: DEFAULT_GUEST_FEEDBACK_STATE,
  updateState: noOpFn
}
