import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { t } from '@local/translations'

export const LearnMore = () => {
  const { state, updateState } = useGuestFeedbackContext()
  const optIn = state.contactInformation?.optIn

  const onToggleOptIn = () => {
    updateState({
      contactInformation: {
        optIn: !optIn
      }
    })
  }

  return (
    <div className='pt-2'>
      <Checkbox
        label={
          optIn
            ? t('yes-you-can-contact-me-to-learn-more')
            : t('no-you-cant-contact-me-to-learn-more')
        }
        checked={optIn}
        onChange={onToggleOptIn}
      />
    </div>
  )
}
