import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { useGuestFeedbackUrlData } from '../../hooks/useGuestFeedbackUrlData'
import {
  GuestFeedbackOutcomeError,
  GuestFeedbackOutcomeExpired,
  GuestFeedbackOutcomeNegative,
  GuestFeedbackOutcomePositive
} from '../GuestFeedbackOutcome/GuestFeedbackOutcome'
import { GuestFeedbackForm } from '../GuestFeedbackForm/GuestFeedbackForm'

export const PageContent = () => {
  const { isBadUrlData } = useGuestFeedbackUrlData()
  const { isError, feedbackState, feedback, state, isComplete } =
    useGuestFeedbackContext()

  if (isBadUrlData || isError || !feedback || feedbackState === 'ERROR') {
    return <GuestFeedbackOutcomeError />
  }

  if (feedbackState === 'EXPIRED') {
    return <GuestFeedbackOutcomeExpired />
  }

  if (isComplete) {
    if (
      state.feedbackRating === 'POSITIVE' ||
      state.feedbackRating === 'SOMEWHAT_POSITIVE'
    ) {
      return <GuestFeedbackOutcomePositive />
    }
    return <GuestFeedbackOutcomeNegative />
  }

  return <GuestFeedbackForm />
}
