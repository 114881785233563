import { t } from '@local/translations'
import { getThisYear } from '../../domain/date'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { isIntl } from '../../domain/international'

const LINK_STYLE = 'text-link hover:text-link-hover hover:underline'

export const Footer = () => {
  const { intlProps } = useGuestFeedbackContext()

  return (
    <>
      <p className='p-4 text-center type-subhead text-default'>
        {t('powered-by')}{' '}
        <a
          href='https://pos.toasttab.com/?source=footer'
          className={LINK_STYLE}
        >
          {t('toast')}
        </a>
        <br />
        {t('c-toast-inc')} {getThisYear()}. {t('all-rights-reserved')} |{' '}
        <a
          href='https://pos.toasttab.com/terms-of-service'
          className={LINK_STYLE}
        >
          {t('terms-of-service')}
        </a>{' '}
        |{' '}
        <a
          href='https://blog.toasttab.com/?source=footer'
          className={LINK_STYLE}
        >
          {t('toast-blog')}
        </a>
        <br />
        {isIntl(intlProps) ? (
          <>
            {t(
              'information-you-provide-may-be-used-by-the-merchant-to-contact-you-about-your-feedback-and-improve-your-experience-your-data-will-be-processed-pursuant-to-toasts'
            )}{' '}
            <a href='https://pos.toasttab.com/privacy' className={LINK_STYLE}>
              {' '}
              {t('privacy-statement')}
            </a>
          </>
        ) : (
          <>
            {t(
              'the-information-you-provide-will-be-processed-pursuant-to-toasts'
            )}{' '}
            <a
              href='https://pos.toasttab.com/terms-of-service'
              target='_blank'
              className={LINK_STYLE}
              rel='noreferrer'
            >
              {t('terms-of-service')}
            </a>{' '}
            {t('and')}{' '}
            <a
              href='https://pos.toasttab.com/privacy'
              target='_blank'
              className={LINK_STYLE}
              rel='noreferrer'
            >
              {t('privacy-statement')}
            </a>
          </>
        )}
        . {t('additional-information-for-california-residents-available')}{' '}
        <a
          href='https://pos.toasttab.com/privacy#addendum-a'
          className={LINK_STYLE}
        >
          {t('here')}
        </a>
        .
      </p>
    </>
  )
}
