import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const StarFilledIcon = React.forwardRef(function StarFilledIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M13.85 3.862c-.395-.79-1.175-1.16-1.902-1.177-.718-.018-1.511.305-1.924 1.048a.723.723 0 00-.02.036L8.308 7.264c-.055.109-.19.22-.479.297l-3.863.595-.02.003c-1.708.31-2.314 2.35-1.096 3.568l2.803 2.703c.057.06.135.229.031.488a.75.75 0 00-.041.143l-.7 3.8a.745.745 0 00-.01.068c-.15 1.648 1.524 2.918 3.083 2.139l3.503-1.802a.735.735 0 01.623 0l3.495 1.798.008.004c1.47.734 3.4-.408 3.073-2.205l-.7-3.802-.002-.011c-.037-.185.016-.342.19-.518L21 11.837l.01-.01c1.218-1.218.612-3.257-1.096-3.568l-.02-.003-3.9-.6a.752.752 0 00-.114-.01c-.042 0-.218-.064-.328-.281l-1.698-3.496-.004-.007z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
