import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { FeatureFlags } from '../configs/featureFlags'
import {
  GuestFeedbackCombinedResponse,
  GuestFeedbackQuery,
  formatResponse
} from '../domain/guestFeedbackDetailsContext'
import { useApi } from '../domain/api'
import { useFeatureFlags } from '../context/FeatureFlagsProvider'
import { useGuestFeedbackUrlData } from './useGuestFeedbackUrlData'

interface UseQueryGuestFeedbackProps {
  onLoadSuccess: (data: GuestFeedbackCombinedResponse) => void
  onLoadError: () => void
}

export const useQueryGuestFeedback = ({
  onLoadSuccess,
  onLoadError
}: UseQueryGuestFeedbackProps): GuestFeedbackQuery => {
  const featureFlags = useFeatureFlags()
  const { getGuestFeedback } = useApi()
  const guestFeedbackUrlData = useGuestFeedbackUrlData()
  const { isBadUrlData } = guestFeedbackUrlData
  const { isLoading, isError, data, refetch } = useQuery(
    [
      'queryGuestFeedbackDetails',
      guestFeedbackUrlData,
      featureFlags[FeatureFlags.MENU_ITEM]
    ],
    () =>
      getGuestFeedback({
        ...guestFeedbackUrlData,
        showOrderItemFeedback: featureFlags[FeatureFlags.MENU_ITEM]
      }),
    {
      enabled: !isBadUrlData && !!featureFlags,
      cacheTime: 0,
      staleTime: Infinity,
      onSuccess: (
        requestData: AxiosResponse<GuestFeedbackCombinedResponse>
      ) => {
        onLoadSuccess(formatResponse(requestData?.data))
      },
      onError: onLoadError
    }
  )

  return {
    isLoading,
    isError,
    refetch,
    ...formatResponse(data?.data)
  }
}
