import cx from 'classnames'

import { Button } from '@toasttab/buffet-pui-buttons'
import { CardSelector } from '@toasttab/buffet-pui-card-selector'
import { PhoneInput } from '@toasttab/buffet-pui-phone-input'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import { t } from '@local/translations'

import ThumbsDown from '../../../assets/thumbs-down.svg'
import ThumbsUp from '../../../assets/thumbs-up.svg'
import { FEEDBACK_REASONS } from '../../domain/guestFeedbackDetailsContext'
import { GenericGuestFeedbackForm as GenericGuestFeedbackFormProps } from './useGenericGuestFeedbackForm'

export const GenericGuestFeedbackForm = (
  form: GenericGuestFeedbackFormProps
) => {
  const { errors, submit, submitting, update, values } = form
  const isPositive = values.rating === 'POSITIVE'
  const feedbackCharsLeft = (numChars: number, maxChars: number) => (
    <>{t('x-characters', { count: numChars, max: maxChars })}</>
  )

  return (
    <>
      <div className='flex justify-center items-center gap-3 mt-10'>
        <button
          aria-label={t('rating', { context: 'POSITIVE' })}
          className={cx(
            'transition ease-in-out duration-200 focus-visible:shadow-focus outline-none cursor-pointer rounded text-gray-50 hover:text-primary-75',
            {
              'text-primary-75': isPositive
            }
          )}
          disabled={submitting}
          onClick={() => update({ rating: 'POSITIVE' })}
        >
          <ThumbsUp />
        </button>
        <button
          aria-label={t('rating', { context: 'NEGATIVE' })}
          className={cx(
            'transition ease-in-out duration-200 focus-visible:shadow-focus outline-none cursor-pointer rounded text-gray-50 hover:text-primary-75',
            {
              'text-primary-75': values.rating === 'NEGATIVE'
            }
          )}
          disabled={submitting}
          onClick={() => update({ rating: 'NEGATIVE' })}
        >
          <ThumbsDown />
        </button>
      </div>
      <p className='type-default text-default text-center pt-10'>
        {t('we-value-your-feedback-as-it-helps-us-continually-improve')}
      </p>
      {values.rating && (
        <>
          <p className='type-headline-4 text-default font-medium text-center pt-8'>
            {isPositive ? t('glad-to-hear') : t('sorry-to-hear-that')}
          </p>
          <p className='type-default text-default text-center'>
            {isPositive
              ? t('what-made-it-great')
              : t('what-could-have-been-better')}
          </p>
          <ul className='flex flex-row flex-wrap gap-2 justify-center my-3'>
            {Object.values(FEEDBACK_REASONS).map((reason) => (
              <li key={reason}>
                <CardSelector
                  checked={values.feedbackReasons.includes(reason)}
                  className='text-center'
                  disabled={submitting}
                  hideMultiSelectCheckboxes
                  multiple
                  onChange={() => {
                    update({
                      feedbackReasons: values.feedbackReasons.includes(reason)
                        ? values.feedbackReasons.filter((r) => r !== reason)
                        : [...values.feedbackReasons, reason]
                    })
                  }}
                >
                  <p>{reason}</p>
                </CardSelector>
              </li>
            ))}
          </ul>
          <div className='pt-6'>
            <Textarea
              className='h-28'
              disabled={submitting}
              errorText={
                errors?.textFeedback ? t('feedback-required') : undefined
              }
              invalid={errors?.textFeedback}
              label={t('tell-us-a-bit-more')}
              maxChars={250}
              maxLength={250}
              required
              testId='text-feedback'
              maxCharsHelperTextFunc={feedbackCharsLeft}
              onChange={(event) => update({ textFeedback: event.target.value })}
              value={values.textFeedback}
            />
          </div>
          <div className='pt-6 space-y-4'>
            <TextInput
              disabled={submitting}
              errorText={
                errors?.firstName ? t('first-name-is-required') : undefined
              }
              invalid={errors?.firstName}
              label={t('first-name')}
              onChange={(event) => update({ firstName: event.target.value })}
              required
              testId='first-name'
              value={values.firstName}
            />
            <TextInput
              disabled={submitting}
              label={t('last-name')}
              onChange={(event) => update({ lastName: event.target.value })}
              testId='last-name'
              value={values.lastName}
            />
            <TextInput
              disabled={submitting}
              errorText={
                errors?.email ? t('a-valid-email-is-required') : undefined
              }
              invalid={errors?.email}
              label={t('email-address')}
              onChange={(event) => update({ email: event.target.value })}
              required
              testId='email'
              value={values.email}
            />
            <PhoneInput
              disableCountry
              disabled={submitting}
              errors={
                errors?.phone
                  ? {
                      nationalNumber: 'error.required.phone'
                    }
                  : undefined
              }
              invalid={errors?.phone}
              label={t('phone-number')}
              name='phone'
              onChange={(phone) => {
                update({ phone })
              }}
              testId='phone'
              value={values.phone || {}}
            />
            <Button className='w-full' onClick={submit} disabled={submitting}>
              {submitting ? t('sending') : t('share-feedback')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}
