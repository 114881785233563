import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { t } from '@local/translations'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

import { FeatureFlags } from '../configs/featureFlags'
import {
  DEFAULT_GUEST_FEEDBACK_CONTEXT,
  DEFAULT_GUEST_FEEDBACK_STATE,
  GuestFeedbackContextType,
  GuestFeedbackState,
  createGuestFeedbackState
} from '../domain/guestFeedbackDetailsContext'
import { trackEvent } from '../domain/tracking'
import { useFeatureFlags } from './FeatureFlagsProvider'
import { useGuestFeedbackUrlData } from '../hooks/useGuestFeedbackUrlData'
import { useMutateGuestFeedback } from '../hooks/useMutateGuestFeedback'
import { useQueryGuestFeedback } from '../hooks/useQueryGuestFeedback'

const GuestFeedbackContext = createContext<GuestFeedbackContextType>(
  DEFAULT_GUEST_FEEDBACK_CONTEXT
)

export const useGuestFeedbackContext = () => useContext(GuestFeedbackContext)

interface GuestFeedbackProviderProps {
  children?: ReactNode
}

export const GuestFeedbackProvider = ({
  children
}: GuestFeedbackProviderProps) => {
  const urlData = useGuestFeedbackUrlData()
  const flags = useFeatureFlags()
  const { isBadUrlData } = urlData
  const { showErrorSnackBar } = useSnackBar()
  const [state, setState] = useState<GuestFeedbackState>(
    DEFAULT_GUEST_FEEDBACK_STATE
  )

  useEffect(() => {
    if (isBadUrlData) {
      trackEvent({
        name: 'Bad Url Data',
        tags: { urlData: JSON.stringify(urlData) }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const queryGuestFeedbackOutput = useQueryGuestFeedback({
    onLoadSuccess: (response) => {
      setState(createGuestFeedbackState(response))
      trackEvent({
        name: 'Get Success',
        tags: {
          commentBoxPriority: flags[FeatureFlags.COMMENT_BOX_PRIORITY],
          feedbackState: response?.feedbackState,
          feedbackRating: response?.feedback?.feedbackRating,
          feedbackRawRating: response?.feedback?.feedbackRawRating
        }
      })
    },
    onLoadError: () => {
      trackEvent({ name: 'Get Error' })
    }
  })

  const {
    mutate,
    isLoading: saving,
    isSuccess: isComplete
  } = useMutateGuestFeedback({
    feedbackGuid: queryGuestFeedbackOutput?.guid,
    onSuccess: () => {
      trackEvent({
        name: 'Save Success',
        tags: {
          commentBoxPriority: flags[FeatureFlags.COMMENT_BOX_PRIORITY],
          feedbackRating: state?.feedbackRating,
          feedbackRawRating: state?.feedbackRawRating
        }
      })
    },
    onError: () => {
      showErrorSnackBar(t('there-was-an-error-saving-please-try-again-later'))
      trackEvent({ name: 'Save Error' })
    }
  })

  const value = useMemo(
    () => ({
      ...queryGuestFeedbackOutput,
      isComplete,
      saveFeedback: () => {
        mutate(state)
      },
      saving,
      state,
      updateState: (partialState: Partial<GuestFeedbackState>) => {
        setState({
          ...state,
          ...partialState,
          contactInformation: {
            ...state?.contactInformation,
            ...partialState?.contactInformation
          }
        })
      }
    }),
    [isComplete, mutate, queryGuestFeedbackOutput, saving, state]
  )

  return (
    <GuestFeedbackContext.Provider value={value}>
      {children}
    </GuestFeedbackContext.Provider>
  )
}
