import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const SentimentSatisfiedIcon = React.forwardRef(
  function SentimentSatisfiedIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                d='M12.05 21.05a9 9 0 100-18 9 9 0 000 18z'
                stroke='currentColor'
                strokeWidth={sizes[props.size || 'sm'].strokeWidth}
                strokeMiterlimit={10}
              />
              <path
                d='M8.85 14.75c.9.7 1.9 1.1 3.1 1.1 1.2 0 2.2-.4 3.1-1.1'
                stroke='currentColor'
                strokeWidth={sizes[props.size || 'sm'].strokeWidth}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M9.05 11.15a1 1 0 100-2 1 1 0 000 2zM14.95 11.15a1 1 0 100-2 1 1 0 000 2z'
                fill='currentColor'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
