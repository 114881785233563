import React from 'react'

export const onEnter =
  <T>(fn: () => void) =>
  (event: React.KeyboardEvent<T>) => {
    if (wasEnter<T>(event) && fn) {
      fn()
    }
  }

const wasEnter = <T>(event: React.KeyboardEvent<T>) => {
  return event?.key === 'Enter'
}
