import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const StarIcon = React.forwardRef(function StarIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M13.18 4.197l1.7 3.5c.2.4.6.7 1 .7l3.9.6c1.1.2 1.5 1.5.7 2.3l-2.8 2.7c-.3.3-.5.7-.4 1.2l.7 3.8c.2 1.1-1 1.9-2 1.4l-3.5-1.8c-.4-.2-.9-.2-1.3 0l-3.5 1.8c-1 .5-2.1-.3-2-1.4l.7-3.8c.2-.5.1-1-.2-1.3l-2.8-2.7c-.8-.8-.4-2.1.7-2.3l3.9-.6c.4-.1.8-.3 1-.7l1.7-3.5c.5-.9 2-.9 2.5.1z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
