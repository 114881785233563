import * as React from 'react'
import {
  LDProvider,
  useLDClient,
  useLDClientError,
  useFlags
} from 'launchdarkly-react-client-sdk'
import { useMockContext } from '../../__mocks__/MockProvider'
import { FeatureFlags, FeatureFlagValues } from '../configs/featureFlags'
import { useLdConfig } from '../configs/useLdConfig'

interface FeatureFlagsProviderProps {
  children: React.ReactNode
}

// Note: we can use this flag to avoid calling the launch darkly client when we don't need to.
let USE_LD_CLIENT = true

export const FeatureFlagsProvider = ({
  children
}: FeatureFlagsProviderProps) => {
  const { clientSideID, key } = useLdConfig()
  const url = window.location.href

  if (!url.includes('toasttab.com')) {
    USE_LD_CLIENT = false
  }

  if (!USE_LD_CLIENT) {
    return <>{children}</>
  }

  return (
    <LDProvider
      clientSideID={clientSideID}
      context={!!key ? { key: key } : undefined}
    >
      {children}
    </LDProvider>
  )
}

export const useFeatureFlags = (): FeatureFlagValues => {
  const flags = useFlags<FeatureFlagValues>()
  const lDClient = useLDClient()
  const lDClientError = useLDClientError()

  const isLoading = !lDClient
  const isError = !!lDClientError

  const { mockUseFeatureFlags } = useMockContext()
  if (mockUseFeatureFlags) {
    return mockUseFeatureFlags
  }

  if (isLoading || isError) {
    return {} as FeatureFlagValues
  }

  return {
    [FeatureFlags.MENU_ITEM]: flags?.[FeatureFlags.MENU_ITEM],
    [FeatureFlags.EMOJIS_SURVEY]: flags?.[FeatureFlags.EMOJIS_SURVEY],
    [FeatureFlags.STARS_SURVEY]: flags?.[FeatureFlags.STARS_SURVEY],
    [FeatureFlags.COMMENT_BOX_PRIORITY]:
      flags?.[FeatureFlags.COMMENT_BOX_PRIORITY]
  }
}
