import React from 'react'
import { AxiosPromise, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import { FeatureFlags, FeatureFlagValues } from '../app/configs/featureFlags'
import {
  GenericGuestFeedbackResponse,
  GuestFeedbackCombinedResponse
} from '../app/domain/guestFeedbackDetailsContext'
import { GuestFeedbackUrlData } from '../app/domain/types'
import {
  mockValidGuestFeedbackWithOrderItemsResponse,
  mockValidGuestFeedbackWithoutOrderItemsResponse
} from '../app/mocks'

export interface MockContext {
  mockUseFeatureFlags?: FeatureFlagValues
  mockGetGuestFeedback?: (
    input: GuestFeedbackUrlData & {
      showOrderItemFeedback?: boolean
    }
  ) => Promise<AxiosResponse<GuestFeedbackCombinedResponse>>
  mockPostGuestFeedback?: () => AxiosPromise<GenericGuestFeedbackResponse>
  mockPutGuestFeedback?: () => Promise<AxiosResponse<void>[]>
}

export interface MockProviderProps
  extends Omit<MockContext, 'mockUseFeatureFlags'> {
  mockUseFeatureFlags?: Partial<FeatureFlagValues>
  children: React.ReactNode
}

const MockContext = React.createContext<MockContext>({})

export const axios200Response = <T,>(data: T): AxiosResponse<T> => {
  return {
    data,
    status: 200,
    statusText: 'OK',
    headers: {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: {} as InternalAxiosRequestConfig<any>
  }
}

export const apiAsyncError = (timeout = 500): (() => AxiosPromise) => {
  return () => {
    return new Promise((_resolve, reject) => {
      setTimeout(() => reject(new Error()), timeout)
    })
  }
}

export const apiAsyncResolve = <T,>(data: T, timeout = 500) => {
  return (): AxiosPromise<T> => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(axios200Response(data)), timeout)
    })
  }
}

export const apiMultiple = <T,>(array: Array<() => AxiosPromise<T>>) => {
  let index = 0
  const increaseCount = () => (index += 1)

  return () => {
    const apiFunction = array[index]
    increaseCount()
    return apiFunction()
  }
}

export const MockProvider = ({
  children,
  mockUseFeatureFlags,
  ...apiOverrides
}: MockProviderProps) => {
  return (
    <MockContext.Provider
      value={{
        mockUseFeatureFlags: {
          [FeatureFlags.MENU_ITEM]: false,
          [FeatureFlags.EMOJIS_SURVEY]: false,
          [FeatureFlags.STARS_SURVEY]: false,
          [FeatureFlags.COMMENT_BOX_PRIORITY]: false,
          ...(mockUseFeatureFlags || {})
        },
        mockGetGuestFeedback: (args) => {
          return Promise.all([
            new Promise<AxiosResponse<GuestFeedbackCombinedResponse>>(
              (resolve) => {
                resolve(
                  axios200Response(
                    args.showOrderItemFeedback
                      ? mockValidGuestFeedbackWithOrderItemsResponse
                      : mockValidGuestFeedbackWithoutOrderItemsResponse
                  )
                )
              }
            )
          ]).then(([feedbackResponse]) => {
            return {
              ...feedbackResponse,
              data: {
                ...feedbackResponse.data,
                orderItems: feedbackResponse.data.orderItems || []
              }
            }
          })
        },
        mockPostGuestFeedback: apiAsyncResolve({
          feedbackGUID: 'mock-feedback-guid'
        }),
        mockPutGuestFeedback: () =>
          Promise.all([
            new Promise<AxiosResponse<void>>((resolve) => {
              resolve(axios200Response(undefined))
            })
          ]),
        // override api defaults via props
        ...apiOverrides
      }}
    >
      {children}
    </MockContext.Provider>
  )
}

export const useMockContext = () => {
  return React.useContext(MockContext)
}
