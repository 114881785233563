import { t } from '@local/translations'
import ThumbsUp from '../../../assets/thumbs-up.svg'
import ThumbsDown from '../../../assets/thumbs-down.svg'
import { useGuestFeedbackRating } from '../../hooks/useGuestFeedbackRating'
import { onEnter } from '../../domain/events'

export const ThumbsOptions = () => {
  const { setRating } = useGuestFeedbackRating()

  return (
    <div className='flex justify-center items-center gap-3 mt-10'>
      <div
        role='button'
        tabIndex={0}
        onClick={setRating('POSITIVE')}
        onKeyDown={onEnter(setRating('POSITIVE'))}
        className='outline-none focus:shadow-focus rounded text-gray-50 hover:text-primary-75'
        aria-label={t('rating', { context: 'POSITIVE' })}
      >
        <ThumbsUp />
      </div>
      <div
        role='button'
        tabIndex={0}
        onClick={setRating('NEGATIVE')}
        onKeyDown={onEnter(setRating('NEGATIVE'))}
        className='outline-none focus:shadow-focus rounded text-gray-50 hover:text-primary-75'
        aria-label={t('rating', { context: 'NEGATIVE' })}
      >
        <ThumbsDown />
      </div>
    </div>
  )
}
