import { DEFAULT_GUEST_FEEDBACK_RESPONSE } from './constants'
import { GuestFeedbackCombinedResponse, GuestFeedbackState } from './types'

export const formatResponse = (
  response: GuestFeedbackCombinedResponse | undefined
): GuestFeedbackCombinedResponse => {
  return response || DEFAULT_GUEST_FEEDBACK_RESPONSE
}

export const createGuestFeedbackState = (
  response: GuestFeedbackCombinedResponse
): GuestFeedbackState => {
  return {
    ...response.feedback,
    contactInformation: {
      ...response?.feedback?.contactInformation,
      optIn: true
    },
    feedbackFreeFormText: '',
    complete: true,
    feedbackItems: {},
    orderItems: response.orderItems
  }
}
