import { ThumbsUpIcon, ThumbsDownIcon } from '@toasttab/buffet-pui-icons'
import { CardSelector } from '@toasttab/buffet-pui-card-selector'

import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { onEnter } from '../../domain/events'
import {
  FeedbackRating,
  OrderItem
} from '../../domain/guestFeedbackDetailsContext'
import { t } from '@local/translations'

export const OrderItems = () => {
  const { state, updateState, orderItems } = useGuestFeedbackContext()
  const { feedbackItems } = state

  const onToggleItem = (orderItem: OrderItem, rating: FeedbackRating) => {
    updateState({
      feedbackItems: { ...feedbackItems, [`${orderItem.itemGuid}`]: rating }
    })
  }

  return (
    <div className='flex flex-col gap-2 justify-center mt-4'>
      {orderItems.map((orderItem) => (
        <div
          key={orderItem.itemGuid}
          className='flex flex-row justify-between items-center'
        >
          <p className='text-default p-3'>{orderItem.displayName}</p>
          <div className='flex flex-row gap-4'>
            <CardSelector
              className='text-center h-fit w-fit rounded-full py-4'
              multiple={false}
              hideMultiSelectCheckboxes={true}
              checked={feedbackItems[`${orderItem.itemGuid}`] === 'POSITIVE'}
              onChange={() => onToggleItem(orderItem, 'POSITIVE')}
              onKeyUp={onEnter(() => onToggleItem(orderItem, 'POSITIVE'))}
            >
              <ThumbsUpIcon aria-label={t('rating', { context: 'POSITIVE' })} />
            </CardSelector>
            <CardSelector
              className='text-center h-fit w-fit rounded-full py-4'
              multiple={false}
              hideMultiSelectCheckboxes={true}
              checked={feedbackItems[`${orderItem.itemGuid}`] === 'NEGATIVE'}
              onChange={() => onToggleItem(orderItem, 'NEGATIVE')}
              onKeyUp={onEnter(() => onToggleItem(orderItem, 'NEGATIVE'))}
            >
              <ThumbsDownIcon
                aria-label={t('rating', { context: 'NEGATIVE' })}
              />
            </CardSelector>
          </div>
        </div>
      ))}
    </div>
  )
}
